document.addEventListener('DOMContentLoaded', () => {
    //desktop slider 
    const slidersDesktop = document.querySelectorAll('.js-slider-posts');

    if (slidersDesktop) {
        slidersDesktop.forEach((boxes) => {
            if (boxes) {
                new Swiper(boxes, {
                    slidesPerView: 1.3,
                    spaceBetween: 9,
                    slideToClickedSlide: true,
                    // centeredSlides: true,
                    loop: true,
                    effect: 'slide',
                    speed: 1500,
                    pagination: false,
                    autoplay: {
                        delay: 1000
                    },
                    navigation: false,
                    breakpoints: {
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 16,
                            autoplay: true,
                        },
                        992: {
                            slidesPerView: 3,
                            spaceBetween: 16,
                            autoplay: false,
                            loop: false
                        },
                    }
                });
            }
        });
    }

});